.eCommerce-feed-page {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow-y: hidden;
	.modal__container {
		width: 70vw;
		overflow: unset;
	}
	.topnav .left .title h3 {
		text-transform: none;
	}
	&__create-feed-modal {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		h3 {
			margin-bottom: 3rem;
		}
		&__title {
			color: black;
			font-size: 2.3rem;
		}
		&__slot-selection {
			display: flex;
			flex-direction: row;
			width: 100%;
			margin-bottom: 2rem;
			> :first-child {
				margin-right: 1rem;
			}
			&__item {
				width: 40%;
				margin-bottom: 2rem;
			}
		}
		&__create-button {
			.button-container {
				padding: 2px;
				margin: unset;
				max-width: 25rem;
				min-width: 22rem;
			}
		}
	}
	&__create-label-modal {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		&__title {
			color: black;
			font-size: 2.3rem;
		}
		&__slot-selection {
			width: 25%;
			margin-bottom: 2rem;
			&.extend {
				width: 40%;
			}
			&.text-only {
				width: unset;
				margin: 4rem 1rem 0rem 1rem;
			}
		}
		&__button {
			align-self: flex-end;
			&.add-more {
				display: flex;
				flex-direction: row;
				align-self: flex-start;
				align-items: center;
				margin-left: 1rem;
				p {
					margin-bottom: 1rem;
				}
			}
			.button-container {
				padding: 2px;
				margin: unset;
				max-width: 20rem;
				min-width: 20rem;
			}
		}
		.list-title {
			margin-left: 1rem;
			color: black;
			margin-bottom: 2rem;
		}
		.product-label-list {
			width: 100%;
			max-height: 45rem;
			overflow-y: auto;
			overflow-x: hidden;
			margin-bottom: 1rem;
			-ms-overflow-style: none; /* Internet Explorer 10+ */
			scrollbar-width: none; /* Firefox */
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			&__item {
				border: 0.3rem solid black;
				border-radius: 2rem;
				padding: 2rem 0rem 0rem;
				margin-bottom: 2rem;
				&__label-name {
					width: 95%;
					.input__container {
						width: 50%;
						margin-top: 0rem;
					}
					&__title {
						&__delete {
							margin-left: auto;
							.button-container {
								margin: 0rem;
							}
						}
						margin-left: 1rem;
						color: black;
						display: flex;
						justify-content: flex-start;
					}
				}
				&__condition-group {
					display: flex;
					width: 100%;
					flex-direction: row;
					align-items: center;
					margin-bottom: 1rem;
					margin-left: 1rem;
					&__criteria {
						flex-basis: 85%;
						display: flex;
						flex-direction: row;
						align-items: flex-start;
						&__item {
							&__title {
								color: #9d9d9d;
							}
							margin-right: 1rem;
							flex: 1;
							.input__container {
								margin: 0rem;
							}
						}
						&__button {
							margin-left: 1rem;
							cursor: pointer;
							&.disabled {
								cursor: not-allowed;
							}
							&.delete {
								img {
									width: 23px;
									margin-bottom: 0.7rem;
								}
							}
						}
					}
					&__text {
						margin-left: 1rem;
					}
				}
			}
		}
		.product-label-list:-webkit-scrollbar {
			display: none; /* Chrome */
		}
	}

	&__content {
		margin-top: 2rem;
		@include container-default($flex-direction: column);
		overflow-y: auto;
		overflow-x: hidden;
		height: 100%;
		margin-bottom: 2rem;
		position: relative;
		&__blur {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			// background: rgba(255, 255, 255, 0.9);
			-webkit-backdrop-filter: blur(4px);
			backdrop-filter: blur(4px);
			display: flex;
			align-items: flex-start;
			justify-content: center;
			z-index: 10;
			&__content {
				text-align: center;
				padding: 2rem;
				background: white;
				border-radius: 8px;
				box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
				max-width: 90%;
				margin: 0 auto;
			}
		}
		&__container {
			background-color: $background-gray;
			margin: 2rem 1.5rem;
			height: 100%;
			width: 95%;
			border-radius: 1.4rem;
			&__label-creation {
				background-color: $white;
				border-radius: 1.4rem;
				padding: 2rem;
				@include box-shadow-light();
				margin-bottom: 2rem;
				.ai-content-in-bulk__bulk-generation-list {
					.MuiDataGrid-root {
						border: 1px solid rgba(224, 224, 224, 1);
					}
					.MuiDataGrid-footerContainer {
						min-height: unset;
						border-top: none;
					}
				}
				&__title {
					color: black;
					font-size: 2.3rem;
				}
				&__button-group {
					display: flex;
					flex-direction: row;
					> :first-child {
						margin-right: 1rem;
					}
					&--item {
						align-self: flex-end;
						margin-top: 2rem;
						.button-container {
							padding: 2px;
							margin: unset;
							max-width: 25rem;
							min-width: 22rem;
						}
					}
				}
				&__shop-information {
					font-size: 2.3rem;
				}
			}
			&__table {
				background-color: #fff;
				border-radius: 1.4rem;
				padding: 3rem 2rem 2rem;
				@include box-shadow-light();
				margin-bottom: 2rem;
				&__feed-filter {
					display: flex;
					flex-direction: row;
					align-items: flex-start;
				}
				.custom-table__headers--item {
					margin-left: 0rem;
					flex-basis: 7%;
					min-width: 7%;
				}
				.custom-table__headers {
					> :first-child {
						flex: unset;
						flex-basis: 20%;
						min-width: 20%;
						margin-right: 2rem;
					}
					> :nth-child(2) {
						flex-basis: 14%;
						min-width: 20rem;
						margin-right: 1rem;
					}
					> :nth-child(3) {
						flex-basis: 10%;
						min-width: 14rem;
					}
				}
				&--row {
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					align-items: center;
					width: 100%;
					margin-bottom: 2rem;
					border-bottom: 1px solid #9d9d9d;
					padding-bottom: 1rem;
					> :first-child {
						flex: unset;
						flex-basis: 20%;
						min-width: 20%;
						margin-right: 2rem;
					}
					> :nth-child(2) {
						flex-basis: 14%;
						min-width: 20rem;
						margin-right: 1rem;
					}
					> :nth-child(3) {
						flex-basis: 10%;
						min-width: 14rem;
					}
					&__item {
						flex-basis: 7%;
						min-width: 7%;
						.MuiChip-root {
							margin: 0rem 0rem 0.5rem 0.5rem;
						}
						&__availability {
							border-radius: 1rem;
							padding: 0.7rem;
							width: fit-content;
							color: white;
							&.in_stock {
								background-color: $light-green;
							}
							&.out_of_stock {
								background-color: $alert-color;
							}
							&.total {
								background-color: white;
							}
						}
					}
				}
			}
		}
	}
}
.table-manage-cell {
	display: flex;
	flex-direction: row;
	p {
		text-decoration: underline;
		margin-right: 3rem;
		color: $light-blue;
		cursor: pointer;
	}
}
.supplemental-feed-modal-container {
	.modal__container {
		width: 50vw;
		overflow: unset;
	}
}
